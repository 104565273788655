// import { authHeader } from '../helpers';

export const userService = {
	login,
	logout,
	register
};

function login(email, password) {
	const requestOptions = {
		method: 'POST',
		headers: {
			       'Access-Control-Allow-Origin': '*',
                    'appid': 'xxkEDkCxhrC4T72Vdas4RXpZCnrbP4',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
		},
		body: JSON.stringify({
			"UserName": "Neoturnapp@neomega.com",
			"password": "XzHXq4_K[f&=k}",
		})
	};

	return fetch("https://cors-anywhere.herokuapp.com/https://testauthentication.nmsproducts.com/api/Auth/login", requestOptions)
		.then(handleResponse)
		.then(user => {

			/////////////////////////////////
			const requestOptions1 = {
				method: 'POST',
				headers: {
						   'Access-Control-Allow-Origin': '*',
							'appid': 'xxkEDkCxhrC4T72Vdas4RXpZCnrbP4',
							'Content-Type': 'application/json',
							'Accept': 'application/json',
							'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
				},
				body: JSON.stringify({
					"UserName": email,
					"password":password,
				})
			};
		
			 return fetch("https://cors-anywhere.herokuapp.com/https://testauthentication.nmsproducts.com/api/Auth/login", requestOptions1)
				.then(handleResponse)
				.then(user1 => {
		
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					localStorage.setItem('user', JSON.stringify(user1));
					return user1;
				});
			////////////////////////////////
		});
}

// remove user from local storage to log user out
function logout() {
	localStorage.removeItem('user');
}

// register user request
function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`http://localhost:3001/auth/register`, requestOptions).then(handleResponse);
}


function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// auto logout if 401 response returned from api
				logout();
				// location.reload(true);
			}
			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}
			console.log(data);
		return data;
	});
}