export const appConstants = {
	LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
	LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
	LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
	SUCCESS: 'ALERT_SUCCESS',
	ERROR: 'ALERT_ERROR',
	CLEAR: 'ALERT_CLEAR',
	LOGOUT: 'USERS_LOGOUT',
	REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
	REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
	REGISTER_FAILURE: 'USERS_REGISTER_FAILURE'
};